var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('manage clinic'))?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"my-auto fw-bold text-primary"},[_vm._v("User Permissions")]),_vm._m(0),_c('select-user',{staticClass:"mb-3",model:{value:(_vm.selectedColleague),callback:function ($$v) {_vm.selectedColleague=$$v},expression:"selectedColleague"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-9 col-lg-6"},[(_vm.selectedColleague && !_vm.loadingPermissions)?_c('div',[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-img-top bg-primary p-2 shadow-sm text-center text-white"},[_c('i',{staticClass:"far fa-3x mt-2",class:_vm._f("formatRoleAsIcon")(_vm.selectedColleague.user_roles[0])}),_c('p',{staticClass:"text-capitalize mb-0 fw-bold"},[_vm._v(" "+_vm._s(_vm.selectedColleague.name)+" ")]),_c('p',{staticClass:"text-capitalize my-1"},[_vm._v(" "+_vm._s(_vm.selectedColleague.user_roles[0])+" ")])]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"fw-bold text-primary"},[_vm._v("Permissions")]),_c('div',{staticClass:"mb-3"},_vm._l((_vm.standardPermissions),function(p){return _c('div',{key:p.id,staticClass:"row justify-content-center align-self-center w-100 mx-auto mb-1"},[_c('div',{staticClass:"col mx-auto"},[_c('toggle-button',{attrs:{"value":_vm.isAllowed(p.name),"id":'perm' + p.id,"disabled":_vm.isInherited(p.name),"color":'#03006b'},on:{"change":function($event){_vm.updatePermission(
                            _vm.selectedColleague.id,
                            p.name,
                            _vm.isAllowed(p.name) ? 'disable' : 'enable'
                          )}}}),_c('label',{staticClass:"custom-control-label ms-1 text-capitalize",attrs:{"for":'perm' + p.id}},[_vm._v(_vm._s(p.name)+" "),(_vm.isInherited(p.name))?_c('small',[_c('i',[_vm._v("(inherited from role)")])]):_vm._e()])],1)])}),0),(_vm.selectedColleague.user_roles[0] !== 'owner' && _vm.blockPermissions && _vm.blockPermissions.length > 0)?[_c('h5',{staticClass:"fw-bold text-primary"},[_vm._v("Block Access")]),_vm._l((_vm.blockPermissions),function(p){return _c('div',{key:p.id,staticClass:"row justify-content-center align-self-center w-100 mx-auto mb-1"},[_c('div',{staticClass:"col mx-auto"},[_c('toggle-button',{attrs:{"value":_vm.isAllowed(p.name),"id":'perm' + p.id,"disabled":_vm.isInherited(p.name),"color":'#03006b'},on:{"change":function($event){_vm.updatePermission(
                            _vm.selectedColleague.id,
                            p.name,
                            _vm.isAllowed(p.name) ? 'disable' : 'enable'
                          )}}}),_c('label',{staticClass:"custom-control-label ms-1 text-capitalize",attrs:{"for":'perm' + p.id}},[_vm._v(_vm._s(p.name)+" "),(_vm.isInherited(p.name))?_c('small',[_c('i',[_vm._v("(inherited from role)")])]):_vm._e()])],1)])})]:_vm._e()],2)])]):_vm._e()])])],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"alert alert-primary mb-3"},[_c('i',{staticClass:"far fa-info me-2"}),_vm._v(" Manage user permissions to control aspects to each platform feature within your clinic. ")])}]

export { render, staticRenderFns }