<template>
  <div v-if="$can('manage clinic')" class="container-fluid">
    <div class="row">
      <!-- Tab cards -->
      <div class="col">
        <h4 class="my-auto fw-bold text-primary">User Permissions</h4>
        <p class="alert alert-primary mb-3">
          <i class="far fa-info me-2"></i>
          Manage user permissions to control aspects to each platform feature within your clinic.
        </p>
        <select-user v-model="selectedColleague" class="mb-3" />
        <div class="row">
          <div class="col-sm-12 col-md-9 col-lg-6">
            <div v-if="selectedColleague && !loadingPermissions">
              <div class="card mb-3">
                <div
                    class="card-img-top bg-primary p-2 shadow-sm text-center text-white"
                >
                  <i
                      class="far fa-3x mt-2"
                      :class="
                    selectedColleague.user_roles[0] | formatRoleAsIcon
                  "
                  ></i>
                  <p class="text-capitalize mb-0 fw-bold">
                    {{ selectedColleague.name }}
                  </p>
                  <p class="text-capitalize my-1">
                    {{ selectedColleague.user_roles[0] }}
                  </p>
                </div>
                <div class="card-body">
                  <h5 class="fw-bold text-primary">Permissions</h5>
                  <div class="mb-3">
                    <div
                        v-for="p in standardPermissions"
                        :key="p.id"
                        class="row justify-content-center align-self-center w-100 mx-auto mb-1"
                    >
                      <div class="col mx-auto">
                        <toggle-button :value="isAllowed(p.name)"
                                       :id="'perm' + p.id"
                                       :disabled="isInherited(p.name)"
                                       :color="'#03006b'"
                                       @change="
                            updatePermission(
                              selectedColleague.id,
                              p.name,
                              isAllowed(p.name) ? 'disable' : 'enable'
                            )" />
                          <label
                              class="custom-control-label ms-1 text-capitalize"
                              :for="'perm' + p.id"
                          >{{ p.name }}
                            <small v-if="isInherited(p.name)"
                            ><i>(inherited from role)</i></small
                            ></label
                          >
                      </div>
                    </div>
                  </div>
                  <template
                      v-if="selectedColleague.user_roles[0] !== 'owner' && blockPermissions && blockPermissions.length > 0"
                  >
                    <h5 class="fw-bold text-primary">Block Access</h5>
                    <div
                        v-for="p in blockPermissions"
                        :key="p.id"
                        class="row justify-content-center align-self-center w-100 mx-auto mb-1"
                    >
                      <div class="col mx-auto">
                        <toggle-button :value="isAllowed(p.name)"
                                       :id="'perm' + p.id"
                                       :disabled="isInherited(p.name)"
                                       :color="'#03006b'"
                                       @change="
                            updatePermission(
                              selectedColleague.id,
                              p.name,
                              isAllowed(p.name) ? 'disable' : 'enable'
                            )" />
                        <label
                            class="custom-control-label ms-1 text-capitalize"
                            :for="'perm' + p.id"
                        >{{ p.name }}
                          <small v-if="isInherited(p.name)"
                          ><i>(inherited from role)</i></small
                          ></label
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AssignTeamMember from "../../global/AssignTeamMember";
import SelectUser from "../../../components/users/SelectUser";

export default {
  props: ["path"],
  data() {
    return {
      loadingColleagues: true,
      loadingPermissions: false,
      busy: false,
      permissionList: [],
      colleagues: [],
      selectedColleague: this.$store.getters['auth/user'],
      selectedPermissions: [],
      selectedInheritedPermissions: [],
    };
  },
  watch: {
    selectedColleague() {
      this.fetchUserPermissions(this.selectedColleague.id);
    }
  },
  methods: {
    fetchColleagues() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/practitioners/fetch-clinic-users")
        .then(({ data }) => {
          this.colleagues = data;
          this.loadingColleagues = false;
        });
    },
    fetchPermissions() {
      this.loadingPermissions = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/permissions")
        .then(({ data }) => {
          this.permissionList = data.permissions;
          this.loadingPermissions = false;
        });
    },
    fetchUserPermissions(id) {
      this.loadingPermissions = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/permissions/user/" + id)
        .then(({ data }) => {
          this.selectedPermissions = data.permissions;
          this.selectedInheritedPermissions = data.inheritedPermissions;
          this.loadingPermissions = false;
        });
    },
    updatePermission(id, permission, action) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/permissions/user/" + id, {
          permission: permission,
          action: action,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    isAllowed(name) {
      return this.selectedColleague.user_permissions.includes(name);
    },
    isInherited(name) {
      let permission = this.selectedInheritedPermissions.find(
        (sIP) => sIP.name == name
      );
      if (permission == null) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchPermissions();
    this.fetchColleagues();
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    standardPermissions() {
      return this.permissionList.filter((item) => {
        return !item.name.includes("block");
      });
    },
    blockPermissions() {
      return this.permissionList.filter((item) => {
        return item.name.includes("block");
      });
    },
  },
  components: {
    SelectUser,

    AssignTeamMember,
  },
  filters: {
    formatRoleAsIcon(role) {
      switch (role) {
        case "practitioner":
          return "fa-user-md";
        case "owner":
          return "fa-user-crown";
        case "administrator":
          return "fa-user-cog";
      }
    },
  },
};
</script>

<style>
.user-selection .dropdown-menu {
  max-height: 450px;
  width: 350px;
  overflow-y: auto;
}
</style>